import styles from "./footer.module.css";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { DropdownMenu } from "react-bootstrap";

export function Footer() {
  return (
    <div className={styles.footerContainer}>
      <div className={styles.linksAndLogo}>
        <img
          className={styles.logo}
          src="/images/logo.png"
          alt="footer-logo.png"
        ></img>
        <div className={styles.links}>
          <a href="/" className={styles.navItem}>
            Home
          </a>
          <a href="/about" className={styles.navItem}>
            About Us
          </a>
          <a href="/board" className={styles.navItem}>
            Board
          </a>
          <Dropdown>
            <Dropdown.Toggle
              variant="black"
              className={styles.dropdownToggle}
              id="dropdown-basic"
            >
              Certification
            </Dropdown.Toggle>
            <Dropdown.Menu className={styles.dropdownMenu}>
              <Dropdown.Item
                href="/credentials"
                className={styles.dropdownItem}
              >
                Credentials
              </Dropdown.Item>
              <Dropdown.Item
                href="/testing-options"
                className={styles.dropdownItem}
              >
                Testing Options
              </Dropdown.Item>
              <Dropdown.Item
                href="/policies-page"
                className={styles.dropdownItem}
              >
                Policies
              </Dropdown.Item>
              <Dropdown.Item
                href="/testing-policies"
                className={styles.dropdownItem}
              >
                Testing Policies
              </Dropdown.Item>
              <Dropdown.Item
                href="/special-accommodations"
                className={styles.dropdownItem}
              >
                Accommodations
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <a href="/partners">Partners</a>
          <a href="/contact">Contact us</a>
        </div>
        <div className={styles.socials}>
          <a
            href="https://www.facebook.com/profile.php?id=61558199176925"
            className={styles.icon}
          >
            <FaFacebookF />
          </a>
          <a
            href="https://www.instagram.com/coitb.technology/"
            className={styles.icon}
          >
            <FaInstagram />
          </a>
        </div>
      </div>
      <hr style={{ width: "90%", opacity: "unset" }} />
      <div className={styles.footerText}>
        <div className={styles.extraInformation}>
          <a href="/policy/privacy-confidentiality-and-security-policy">
            Privacy Policy
          </a>
          <a href="/policies-page">Terms of Service</a>
          {/* commented out as we do not have a Coookies Settings policy <a href="#">Cookies Settings</a> */}
        </div>
        <p style={{ marginBottom: 0 }}>
          ©Coalition of Information Technology Businesses. 2024 All rights
          reserved.
        </p>
      </div>
    </div>
  );
}
