import styles from "./NewNav.module.css";
import { useState, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useSpring, animated } from "@react-spring/web";

export function NewNav() {
  const [logo, setLogo] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(false);
  const [hamVisible, setHamVisible] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const toggleMenu = () => {
    setMobileMenu(!mobileMenu);
    setHamVisible(!hamVisible);
  };

  const menuAnimation = useSpring({
    transform: isMenuOpen ? 'translateX(200%)' : 'translateX(0%)',
    config: { friction: 40, tension: 300, mass: 2 },
  });

  const menuAnimationMobile = useSpring({
    transform: mobileMenu ? 'translateY(0%)' : 'translateY(-1500%)',
    config: { friction: 40, tension: 300, mass: 2 },
  });

  const [props, api] = useSpring(() => ({
    opacity: 1,
    transform: 'translateY(0px)',
    color: 'rgb(44, 124, 197)',
  }));

  useEffect(() => {
    if (
      (window.location.pathname === '/about' && windowWidth <= 768) ||
      window.location.pathname === '/contact'
    ) {
      setLogo(false);
    } else {
      setLogo(true);
    }

    let lastScrollTop = 0;

    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    const updateScroll = (isScrollingDown) => {
      if (windowWidth > 768) {
        setIsMenuOpen(isScrollingDown);
      }
      api.start({
        opacity: isScrollingDown ? 0 : 1,
        transform: isScrollingDown ? 'translateY(-50px)' : 'translateY(0px)',
        color: isScrollingDown ? 'rgba(0, 0, 0, 0)' : 'rgb(44, 124, 197)',
      });
    };

    const handleScroll = () => {
      let st = window.scrollY || document.documentElement.scrollTop;
      if (st > lastScrollTop && windowWidth > 768) {
        updateScroll(true);
      } else if (windowWidth > 768) {
        updateScroll(false);
      }
      lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, [api, windowWidth]);

  //<img src={logo} alt="COITB Logo" className={styles.logo} />
  return (
    <>
      <div className={styles.logoContainer}>
        <img
          src={logo ? "/images/logo.png" : "/images/logo.png"}
          alt="COITB Logo"
          className={styles.logo}
        />
        {/* <animated.p
          style={{
            ...props,
            color: logo ? 'rgb(44, 124, 197)' : 'white',
          }}
          className={styles.logoText}
        >
          coalition of information technology businesses
        </animated.p> */}
      </div>
      {windowWidth > 768 ? (
        <div className={styles.navParent}>
          <animated.div className={styles.navContainer} style={menuAnimation}>
            <div className={styles.items}>
              <a href="/" className={styles.navItem}>Home</a>
              <a href="/about" className={styles.navItem}>About</a>
              <Dropdown className={styles.navDrop}>
                <Dropdown.Toggle id="dropdown-basic">Certification</Dropdown.Toggle>
                <Dropdown.Menu className={styles.dropDown}>
                  <Dropdown.Item href="/credentials">Credentials</Dropdown.Item>
                  <Dropdown.Item href="/testing-options">Testing Options</Dropdown.Item>
                  <Dropdown.Item href="/policies-page">Policies</Dropdown.Item>
                  <Dropdown.Item href="/testing-policies">Testing Policies</Dropdown.Item>
                  <Dropdown.Item href="/special-accommodations">Accommodations</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <a href="/partners" className={styles.navItem}>Partners</a>
              <a href="/board" className={styles.navItem}>Board</a>
              <a href="/contact" className={styles.navItem}>Contact Us</a>
            </div>
          </animated.div>
        </div>
      ) : (
        <div className={styles.navParent}>
          <div className={styles.hamburger} onClick={toggleMenu}>
            <div className={styles.hamburgerLine}></div>
            <div className={styles.hamburgerLine}></div>
            <div className={styles.hamburgerLine}></div>
          </div>
          <animated.div className={styles.navContainer} style={menuAnimationMobile}>
            <div className={styles.items}>
              <a href="/" className={styles.navItem}>Home</a>
              <a href="/about" className={styles.navItem}>About</a>
              <Dropdown className={styles.navDrop} drop="start">
                <Dropdown.Toggle id="dropdown-basic">Certification</Dropdown.Toggle>
                <Dropdown.Menu className={styles.dropDown}>
                  <Dropdown.Item href="/credentials">Credentials</Dropdown.Item>
                  <Dropdown.Item href="/testing-options">Testing Options</Dropdown.Item>
                  <Dropdown.Item href="/policies-page">Policies</Dropdown.Item>
                  <Dropdown.Item href="/testing-policies">Testing Policies</Dropdown.Item>
                  <Dropdown.Item href="/special-accommodations">Accommodations</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <a href="/partners" className={styles.navItem}>Partners</a>
              <a href="/board" className={styles.navItem}>Board</a>
              <a href="/contact" className={styles.navItem}>Contact Us</a>
            </div>
          </animated.div>
        </div>
      )}
    </>
  );
}
