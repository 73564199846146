import React from "react";
import styles from "./partnersPage.module.css";
import { PartnerCard } from "../../components/partnerCard/PartnerCard";
//props for PartnerCard component, replace with actual data from DB
export const Box = () => {

  return (
    <div className={styles.container}>
      <div className={styles.box}>
        <div className={styles.textGroup}>
          <div className={styles.partnerWithCoitb}>
            Partner with
            CoITB
          </div>
          <p className={styles.textWrapper}>
            Join forces with our trusted platform, become a accredited coalition
            of information technology businesses Partner.
          </p>
        </div>
          <div className={styles.headerImgContainer}>
              <img className={styles.headerImg} src={`${process.env.PUBLIC_URL}/images/partners-images/partnersCover.png`} alt={"Cover Image"}/>
          </div>
      </div>
      <div className={styles.partnerSection}>
        <div className={styles.partnerContainer}>
          {/* These will be mapped out from partners that come from DB, or hard coded values. */}
          <PartnerCard
            partner={{
              logo: "/images/MilitarySolution-Logo-Main.jpg",
              name: "Military Solution",
              description:
                "Helping Veterans transition into great post-military careers for over 10 years. We focus on jobs and companies most Veterans won’t find on their own. Best of all? These jobs feature above-average wages and the employers provide the training.",
              link: "https://militarysolution.net/",
            }}
          />
          <PartnerCard
            partner={{
              logo: "/images/partners-images/CTG.png",
              name: "Close The Gap consulting LLC",
              description:
                "CTG provides time honored military senior enlisted consulting services to firms operating in industries at the intersection of supporting Department of Defense initiatives with sound business development, capture management, proposal development, and program management support.",
              link: "https://www.closethegapconsultingllc.com/",
            }}
          />
          <PartnerCard
            partner={{
              logo: "/images/placeholder-images/eraLogo.png",
              name: "Era Solutions",
              description:
                "Era Solutions is a remarkable Service-Disabled Veteran Owned Small Business (SDVOSB) with a dedicated mission to train and employ veterans, placing them in highly desirable careers. Simultaneously, we provide top-tier HR and talent management solutions to businesses of all sizes.",
              link: "https://www.erasolutions.us/",
            }}
          />
          <PartnerCard
            partner={{
              logo: "/images/totalCyber.png",
              name: "Total Cyber Solutions",
              description: "Total Cyber’s Core services are Vulnerability Assessments and Penetration Testing Services. We conduct our assessments according to NIST standards. We provide Managed Cybersecurity Services to protect our customers’ computing environments.",
              link: "https://www.totalcyber.com/",
            }}
          />
          <PartnerCard
            partner={{
              logo: "/images/Vets4l.png",
              name: "Veterans Solutions 4 Life",
              description: "Veterans Solutions 4 Life provides “High Touch” Virtual Training and Support to all our Service Members and Families. We have developed a higher-level strategy to ensure no one is left behind. This adheres to all our company practices, ensuring the success of all our clients and their journey into the future.",
              link: "https://vets4l.com/",
            }}
            
          />
           {/* <PartnerCard
            partner={{
              logo: "/images/vetcom.png",
              name: "Vet-Com.org",
              description: "Vet-Com's core is dedicated to promoting opportunities for veterans by providing comprehensive support through education, mentorship, and entrepreneurship. Our mission is to empower veterans with the tools they need to succeed, fostering their growth and ensuring their seamless transition into civilian life.",
              link: "https://www.vet-com.org/",
            }}
            
          /> */}
          <PartnerCard
            partner={{
              logo: "/images/alginc.png",
              name: "A.L. Griffith, Inc.",
              description: "A.L. Griffith, Inc., is a Service Disabled, Veteran Owned Small Business specializing in custom software development, IT and AI training, data services, and nanotechnology! Simple. Effective. Efficient.",
              link: "https://algriffith.com",
            }}
          />
        </div>
      </div>
      <div className={styles.contactContainer}>
          <div className={styles.formContainer}>
              <div className={styles.form}>
                  <iframe
                      src="https://forms.monday.com/forms/embed/7682caa0369cf7f8f6d986f2a8cf12ff?r=use1%22"
                      style={{
                          width: "100%",
                          height: "100%",
                      }}
                  ></iframe>
              </div>
              <div className={styles.formContent}>
                  <img src="./images/partners-images/handShake.png" alt="Hand-shake"/>
                  <h2>
                      Interested in becoming a partner?
                      <br/>
                      Contact us!
                  </h2>
              </div>
          </div>
      </div>
    </div>
  );
};
