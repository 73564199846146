import React from "react";
import styles from "./About.module.css";

export default function AboutPage() {
  return (
    <div className={styles.container}>
      <div className={styles.box}>
        <img src="/images/about-images/aboutUsCover.png" alt="" />
        <div className={styles.textGroup}>
          <h1 className={styles.title}>About Us</h1>
        </div>
      </div>
      <div className={styles.graphSection}>
        <div className={styles.graphText}>
          <h3>CoITB</h3>
          <p>
            Uniting the realms of IT through pioneering certification and growth
            support for businesses at every scale. We champion innovation and
            foster excellence, propelling the tech industry forward.
          </p>
        </div>
      </div>
      <div className={styles.mission}>
        <div className={styles.missionheader}>
          <img src="/images/about-images/rocket.png" alt="" />
          <h1>Our Mission</h1>
        </div>
        <div className={styles.missionCards}>
          <div className={styles.missionCard}>
            <div
              className={styles.front}
              style={{
                backgroundImage: "url(/images/about-images/asset-2.png)",
                backgroundColor: "#E63946",
              }}
            >
              <h2>Inspiration</h2>
            </div>
            <div
              className={styles.back}
              style={{
                backgroundColor: "#E63946",
              }}
            >
              <h2>Inspiration</h2>
              <p>
                Inspire businesses to embrace and integrate cutting-edge
                technologies, thereby fostering an environment of continuous
                innovation and growth.
              </p>
            </div>
          </div>
          <div className={styles.missionCard}>
            <div
              className={styles.front}
              style={{
                backgroundImage: "url(/images/about-images/asset-1.png)",
                backgroundColor: "#58C1D8",
                backgroundSize: "115% 50%",
              }}
            >
              <h2>Talent Development</h2>
            </div>
            <div
              className={styles.back}
              style={{
                backgroundColor: "#58C1D8",
              }}
            >
              <h2>Talent Development</h2>
              <p>
                Expand the talent pipeline by attracting, nurturing, and
                retaining top-tier talent within the IT sector, thereby securing
                the future of the industry.
              </p>
            </div>
          </div>
          <div className={styles.missionCard}>
            <div
              className={styles.front}
              style={{
                backgroundImage: "url(/images/about-images/asset-3.png)",
                backgroundColor: "#055487",
                backgroundSize: "100% 80%",
              }}
            >
              <h2>Collaboration</h2>
            </div>
            <div
              className={styles.back}
              style={{
                backgroundColor: "#055487",
              }}
            >
              <h2>Collaboration</h2>
              <p>
                Cultivate a community of technology enterprises united by common
                goals and objectives, thereby amplifying collective success
                through shared achievements.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.vision}>
        <div className={styles.visionText}>
          <h2>Our Vision</h2>
          <p>
            At the heart of COITB lies a visionary aspiration: to cultivate an
            ecosystem where technology businesses, irrespective of their size or
            stage of development, can flourish. We endeavor to create a
            landscape where innovation is the currency and where the IT
            workforce of tomorrow is nurtured today. By championing
            collaboration and fostering an environment ripe for technological
            advancement, we aim to accelerate the pace of progress, ensuring
            that the IT industry remains at the cutting edge of change.
          </p>
        </div>
      </div>
      <div className={styles.contactContainer}>
        <div className={styles.contactForm}>
          <iframe
            src="https://forms.monday.com/forms/embed/7682caa0369cf7f8f6d986f2a8cf12ff?r=use1"
            style={{
              width: "100%",
              height: "100%",
            }}
            title="contact-form"
          ></iframe>
        </div>
        <div className={styles.formContent}>
          <div className={styles.formText}>
            <h2>
              Join us
              <hr
                style={{
                  opacity: "unset",
                  color: "white",
                  borderWidth: "2px",
                  marginTop: "0",
                }}
              />
            </h2>
            <p>
              as we navigate the digital age's challenges and opportunities.
              Together, let's shape a future where technology drives lasting
              social and economic progress.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
