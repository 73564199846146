import "./homePage.css";
import "./responsive.css";
import { HashLink as Link } from "react-router-hash-link";
import { TypeAnimation } from "react-type-animation";

const imageAssets = {
  landingCover: "/images/home-images/LandingHero.png",

  lightbulbLaptop: "/images/home-images/lightbulbLaptop.png",
};

const boardObj = [
  {
    id: 0,
    src: "/images/board-images/DKT.png",
    src2: "/images/board-images/DK.png",
    alt: "board1.jpg",
    cardHeader: "Danny Ken",
    cardInfo: "Secretary",
  },
  {
    id: 1,
    src: "/images/board-images/KCT.png",
    src2: "/images/board-images/KC.png",
    alt: "board2.jpg",
    cardHeader: "Kyle Crump",
    cardInfo: "Vice Chairman",
  },
  {
    id: 2,
    src: "/images/board-images/HGT.png",
    src2: "/images/board-images/HG.png",
    alt: "board3.jpg",
    cardHeader: "Hector Guerra",
    cardInfo: "Chairman and President",
  },
  {
    id: 3,
    src: "/images/board-images/unisexProfilePic.jpeg",
    src2: "/images/board-images/unisexProfilePic.jpeg",
    alt: "board2.jpg",
    cardHeader: "Brandon Martin",
    cardInfo: "Treasurer",
  },
  {
    id: 4,
    src: "/images/board-images/TM-fixed.png",
    src2: "/images/board-images/TM.jpg",
    alt: "board3.jpg",
    cardHeader: "Thomas Miller",
    cardInfo: "Director of Military Programs",
  },
];

export default function HomePage() {
  return (
    <div>
      <section className="heroSection">
        <div className="homeHeroInfoContainer">
          <h1 className="homeHeroInfoHeader">
            <TypeAnimation
              sequence={[
                "Coalition of Information Technology Businesses",
                1000,
              ]}
              speed={40}
              style={{ fontSize: "0.6em" }}
              repeat={Infinity}
            />
          </h1>
        </div>
      </section>
      <section className="aboutSection">
        <p className="aboutSectionText">
          At CoITB, we strive to promote innovation, growth, and excellence in
          Information Technology. Our mission is to empower and support various
          IT businesses, including startups in SAAP, SAAS, Artificial
          Intelligence, and comprehensive enterprise services. We are committed
          to adding value to the growth of small businesses, guiding them
          through the dynamic technological landscape.
          <Link to="/about" className="aboutSectionLink">
            Learn More
          </Link>
        </p>
        <img
          src={imageAssets.lightbulbLaptop}
          alt="lightbulbLaptop"
          className="lightbulbLaptop"
        />
      </section>
      <section className="contactSection">
        <div className="leftSide">
          <img
            className="contactUsBg"
            src="https://api.mapbox.com/styles/v1/mapbox/streets-v12/static/pin-l+ff0000(-97.5646,35.5276)/-97.5646,35.5276,15.24,0/500x500@2x?access_token=pk.eyJ1IjoiZzAwZGt1NWgiLCJhIjoiY2x0MzZxYmJkMXYwNjJscGhjem5uam0yNiJ9.OEyKh2762F_ZwpoQ3JwoIw"
            alt="map"
          ></img>
        </div>
        <div className="rightSide">
          <div className="contactUs">
            <iframe
              src="https://forms.monday.com/forms/embed/690811b433684ac7c46519ce893fc217?r=use1"
              title="form"
              style={{
                style: "border: 0",
                boxShadow: "5px 5px 56px 0px rgba(0,0,0,.25)",
              }}
            ></iframe>
          </div>
        </div>
      </section>
      <section className="boardSection">
        <div className="boardImgContainer">
          <img
            className="boardImg"
            src="/images/home-images/fancy-bg-1.jpg"
            alt="fancy-bg"
          />
        </div>
        <div className="boardContentContainer">
          <div className="boardHeaderContainer">
            <p className="boardSubHeader">Our Experts</p>
            <p className="boardHeader">Meet the Board</p>
            <p className="boardDescription">
              Meet our board! Comprised of seasoned experts and visionary
              leaders, each member brings a unique set of skills and experiences
              to the table. Together, this diverse and accomplished board of
              directors guides the Coalition of Information Technology
              Businesses towards its mission of fostering the next generation of
              IT professionals and driving industry growth.
            </p>
          </div>
          <div className="boardCardsContainer">
            {boardObj.map((item) => (
              <div className="boardCard">
                <img src={item.src} alt={item.alt}></img>
                <div className="boardCardContentContainer">
                  <p className="boardCardHeader">{item.cardHeader}</p>
                  <p className="boardCardInfo">{item.cardInfo}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}
